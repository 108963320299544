import React from 'react';
import LandingPageTemplate from '../../components/LandingPageTemplate';

import mainImage from '../../images/landingpage_hersteller_1600_900_v4.webp';
import mainImage800 from '../../images/landingpage_hersteller_800.webp';


const context = {
  title: 'Hersteller im Bereich Türautomation', // Große Haupt-Überschrift
  subTitle: 'Automatiktür24 - aktuelle Themen', // Kleine Seiten-Überschrift über der Haupt-Überschrift
  description: 'Übersicht aller Automatiktür Hersteller und Fachverbände',
  image: mainImage, //Haupt-Bild der Landingpage
  image800: mainImage800, //Haupt-Bild der Landingpage
  imageAlt: "Automatiktür-Hersteller von A-Z", //Alternativtext / Beschreibung zum Haupt-Bild
  contactButtonText: "Automatiktür-Beratung anfordern",
  configurator: true, // optional, aktiviert den Konfirgurator Button wenn 'true'
  configuratorButtonText: "Schiebetür online konfigurieren", // optional, überschreibt den default Titel
  suggestedProductsTitle: "Passende Automatiktür24 Produkte", // optional, überschreibt den default Titel
  suggestedProducts: [
    '/produkte/automatische-schiebetuer/',
    '/produkte/automatische-teleskopschiebetuer/',
    '/produkte/break-out-automatikschiebetuer/'
  ],
  text:
  <div>
    <p className="italic">Alle Automatiktür-Hersteller von A-Z</p>
    <ul className="infolist pb-16">
      <li><h3>ASSA ABLOY</h3> Assa Abloy ist eine schwedische, weltweit operierende Unternehmensgruppe, die Schließsysteme, elektromechanische Produkte, Sicherheitstüren und Beschläge produziert und vertreibt. Die Division Entrance Systems produziert, vertreibt und wartet automatische Türsysteme. Produktmarken im Unternehmensverbund sind Besam (Automatiktüren), Crawford (Tore), Ditec Entrematic (Automatiktüren) und Albany (Schnelllauftore).</li>
      <li><h3>BOON EDAM</h3> Boon Edam ist ein niederländisches Unternehmen, welches Produkte im Bereich Karusselltüren, Sicherheitstüren und Sensorschleusen entwickelt und Kunden auf der ganzen Welt beliefert.</li>
      <li><h3>DEUTSCHTEC</h3> Deutschtec mit Sitz in Petershagen ist ein Hersteller automatischer Türsysteme, welche die Anforderungen an moderene Architektur erfüllen sollen und bietet hier eine Auswahl an Automatikschiebetüren, Rundbbogenschiebetüren, Teleskopschiebetüren und deren Profilsysteme an.</li>
      <li><h3>DORMAKABA</h3> Die dormakaba Holding ist ein weltweiter Anbieter für Zugangslösungen und –Serviceleistungen in den Bereichen Türschließtechnik, Türautomatik, mobile Raumtrennsysteme und Glasbeschlagtechnik. Produktmarken im Unternehmensverbund sind beispielsweise KABA Gallenschütz (Zutrittskontrollsysteme).</li>
      <li><h3>FAAC</h3> FAAC ist ein italienischer Hersteller mit deutscher Vertriebsniederlassung. Seine Produktpalette reicht von unterschiedlichen Torantrieben über Schrankenanlagen bis hin zu automatischen Schiebetüre und Drehflügeltürantrieben.</li>
      <li><h3>FACE</h3> Face Automatic Doors ist ein Unternehmen welches automatische Türsysteme designt, produziert und entwickelt und hat seinen Hauptsitz in Italien. Spezialisiert auf automatische Drehflügeltürantriebe und Schiebetüren, soll die neue Generation an Türantrieben ausschließlich mit bürstenlosen Motoren angetrieben werden.</li>
      <li><h3>FACHVERBAND TÜRAUTOMATION E.V.</h3> Der FTA stärkt die Branche der Türautomation und fördert den Einsatz von automatischen Türsystemen.</li>
      <li><h3>GEZE</h3> Geze mit Sitz in Leonberg ist ein Unternehmen zur Entwicklung und Fertigung von Systemen der Tür-, Fenster- und Sicherheitstechnik. GEZE bietet in seiner Produktpalette alles rund um automatische Türsysteme, Türtechnik, Rauch- und Wärmeabzugsanlagen (RWA) sowie Fenster- und Lüftungstechnik.</li>
      <li><h3>GILGEN DOOR SYSTEMS</h3> Gilgen Door Systems ist ein Anbieter von Antriebssystemen und Komplettanlagen für Tür- und Torsysteme mit dem Hauptsitz in der Schweiz. Das Leistungsspektrum erstreckt sich von der Entwicklung bis zur Produktion der automatischen Türsysteme, Sicherheitstüren, diversen Torsystemen und wandelbaren Wandsystemen.</li>
      <li><h3>GRETSCH-UNITAS</h3> Gretsch-Unitas ist der Anbieter von Fenster- und Türtechnik, Automatischen Eingangs- sowie Gebäudemanagementsystemen. Das Sortiment bietet Sicherheits- und Beschlaglösungen für das Eigenheim, für barrierefreies Bauen, für Türen in Flucht- und Rettungswegen bis hin zu individuellen Fassadenlösungen. Die Marke GU Automatic, ehemals ATS, hat sich zu einem der führenden Anbieter von automatischen Schiebetüren, Drehflügelantriebe, Rundbogenschiebetüren, Karusselltüren, Sicherheitsschleusen, Glas-Schiebewänden und Lösungen zur Personenvereinzelung entwickelt.</li>
      <li><h3>HÖRMANN</h3> Hörmann mit Sitz im ostwestfälischen Steinhagen ist ein deutscher Hersteller von Toren, Türen, Zargen und Antrieben. Die Produktpalette wurde seither ständig erweitert und umfasst heute auch Sektional-, Roll-, Feuerschutz- und Schnelllauftore, Torantriebe und Steuerungen sowie Verladetechniksysteme, Feuer- und Rauchschutzabschlüsse, Haus- und Innentüren und Zargen.</li>
      <li><h3>KONE</h3> Kone Corporation ist ein finnischer Hersteller für Aufzugs- und Fahrtreppenanlagen. Der Schwerpunkt des Unternehmens ist das Aufzugs- und Rolltreppengeschäft, seit 2012 wird über die Marke KONE Automatiktüren, ehemals Konematic und Waldoor, das Geschäft mit automatischen Türen und Toren sowie Verladebrücken abgewickelt.</li>
      <li><h3>METAXDOOR</h3> Metaxdoor ist Hersteller von Ein- und Durchgangslösungen für unterschiedliche Nutzungsbereiche in Krankenhäusern, Flughäfen und Einkaufszentren. Karusselltüren, Rundbogenschiebetüren und Standard Schiebetüren gehören hier zu ihrem Portfolio.</li>
      <li><h3>RECORD TÜRAUTOMATION</h3> Record Türautomation ist ein schweizer Hersteller von automatischen Türsystemen. Die Unternehmensgruppe entwickelt, produziert, vertreibt, installiert und wartet automatische Türanlagen. Dazu zählen automatische Schiebetüren, Winkelschiebetüren, Falttüren, Drehflügeltürantriebe, automatische Türsysteme für Sicherheitsanforderungen, Sicherheitsschleusen, Brandschutztüren, Karusselltüren und Schnelllauftore. Produktmarken im Unternehmensverbund sind BLASI (Karusselltüren) und KOS Spezialtüren (hermetische Türen).</li>
      <li><h3>TORMAX</h3> Tormax einer schweizer Hersteller für automatische Türsysteme mit deutschen Partnerschaften. Die automatischen Schiebetüren, Drehflügeltüren, Faltflügeltüren und Karusselltüren trifft man in Bürogebäuden, in Einkaufszentren, in Hotels und Restaurants, auf Flughäfen und Apotheken an.</li>
    </ul>
  </div>
}

const Page = () => {

  return(
    <LandingPageTemplate pageContext={context} />
  )
}

export default Page;
